import {
  ContentfulImage,
  ContentfulImageSchema
} from '@ecomm/contentful/components'
import { Modal } from '@ecomm/shared-components'
import { HelpCircle, LightBulb, PencilAndRuler } from '@ecomm/shared-icons'
import { XCircleSolidBold } from '@ecomm/shared-icons'
import { PhoneLeadCaptureForm } from './PhoneLeadCaptureForm'

export type ExitIntentSchema = {
  readonly background: ContentfulImageSchema
  readonly specialist: ContentfulImageSchema
}

export function ExitIntentModalContent({
  assets: { background, specialist }
}: { readonly assets: ExitIntentSchema }) {
  return (
    <div
      className="overflow-auto px-7 [@media(min-height:578px)]:[@media(min-width:768px)]:px-10 text-white bg-center bg-no-repeat bg-cover relative w-full h-full"
      style={{
        backgroundImage: `url(${background.url})`
      }}
    >
      <div className="flex items-center">
        <h2 className="text-primary-100 text-center text-4xl leading-9 [@media(min-height:578px)]:[@media(min-width:768px)]:text-[56px] tracking-tight font-medium [@media(min-height:578px)]:[@media(min-width:768px)]:font-semibold [@media(min-height:578px)]:[@media(min-width:768px)]:leading-[3.5rem] mb-0 mt-[42px] [@media(min-height:578px)]:[@media(min-width:768px)]:mt-11 font-arizona">
          Still have questions?
        </h2>
        <div className="block [@media(min-height:578px)]:[@media(min-width:768px)]:hidden self-end mr-4">
          <ContentfulImage
            classNameOverride="bg-transparent"
            {...specialist}
            height={66}
            quality={90}
            width={66}
          />
        </div>
      </div>
      <div className="flex items-center border-0 border-solid border-b border-slate-400">
        <h3 className="text-xl [@media(min-height:578px)]:[@media(min-width:768px)]:text-[26px] mb-2 [@media(min-height:578px)]:[@media(min-width:768px)]:mb-6 font-normal tracking-tight ml-12 mt-2 [@media(min-height:578px)]:[@media(min-width:768px)]:mt-5 text-center [@media(min-height:578px)]:[@media(min-width:768px)]:text-right text-white font-arizona">
          Talk to a sales specialist
        </h3>
        <div className="ml-4 hidden [@media(min-height:578px)]:[@media(min-width:768px)]:block">
          <ContentfulImage
            classNameOverride="bg-transparent"
            {...specialist}
            height={50}
            quality={90}
            width={50}
          />
        </div>
      </div>
      <div className="hidden [@media(min-height:578px)]:[@media(min-width:768px)]:block">
        <ul className="p-0">
          <ol className="flex p-0 justify-center my-3 font-light">
            <PencilAndRuler className="h-5 w-5 mr-3" /> Customize a system for
            your needs.
          </ol>
          <ol className="flex p-0 justify-center my-3 font-light">
            <HelpCircle className="h-5 w-5 mr-3" /> Answer your equipment &
            monitoring questions.
          </ol>
          <ol className="flex p-0 justify-center my-3 font-light">
            <LightBulb className="h-6 w-6 mr-3" /> Tips for your home & budget.
          </ol>
        </ul>
      </div>
      <div className="mt-2 [@media(min-height:578px)]:[@media(min-width:768px)]:mt-6 [@media(min-height:578px)]:[@media(min-width:768px)]:w-10/12 mx-auto">
        <PhoneLeadCaptureForm />
      </div>
    </div>
  )
}

type Props = {
  readonly isOpen: boolean
  readonly onRequestClose: () => void
  readonly assets: ExitIntentSchema
  readonly json: { readonly tag: string }
}

export function ExitIntentModal({
  assets,
  isOpen,
  onRequestClose,
  json
}: Props) {
  return (
    // This wrapping div and its classes are added to make sure it overlaps with the user's viewport so the ninetailed tracking
    // calls are being sent. See https://docs.ninetailed.io/for-developers/experience-sdk/rendering-experiences#tracking-impressions-of-experiences
    <div
      className="w-full h-full fixed top-0 left-0 pointer-events-none"
      data-testid="ExitIntentModal"
    >
      {json.tag === 'variant' ? (
        <Modal
          className="!bg-transparent !w-[346px] !h-[408px] [@media(min-height:578px)]:[@media(min-width:768px)]:!w-[487px] [@media(min-height:578px)]:[@media(min-width:768px)]:!h-[578px] !shadow-none max-h-full"
          closeIcon={<XCircleSolidBold className="h-7 w-7" />}
          closeIconClassname="!opacity-100 !top-0 [@media(min-height:578px)]:[@media(min-width:768px)]:!top-[16px]"
          isOpen={isOpen}
          onRequestClose={onRequestClose}
        >
          <ExitIntentModalContent assets={assets} />
        </Modal>
      ) : null}
    </div>
  )
}
